import React, {useState, useEffect} from 'react';
import "./styles.css";
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faCircleDown, faBell} from '@fortawesome/free-solid-svg-icons';




function Dashboard() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    document.title = "Home Page | Datazapp Count System";
  }, []);


  return (
    <div className='dashboard-container'>
      <Navbar toggleSidebar={toggleSidebar}/> 
      <div className='dashboard-content'>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} currentPage='home' />

      {/*User Home Page Started*/}

        <div className={`homepage ${isSidebarOpen ? 'shifted' : ''}`}> 
        
        <div className='homepage-title'>
        <h2> User Home Page</h2>
        <div className='home-dashboard'>
        <FontAwesomeIcon icon={faHome} className='home-icon'/>
        <span className='home-text'>Home</span>
        <span>&gt;</span>
        <span className='dashboard-text'>Dashboard</span>
       </div>  
       </div>

         {/* Three Cards */}
        <div className='card-container'>
         <div className='card-wrapper'>
          <div className='row'>
            <div className='col-md-4'>
            <div className='card card1'>
         <h3>Build <br/> A List</h3>
         <p>Choose List Type</p>
         <FontAwesomeIcon icon={faCircleDown} className='down-arrow-icon' />
         </div>
          </div>
        </div>
          {/* <div className={`col-md-4 ${isSidebarOpen ? '' : 'mx-auto'}`}> */}
        </div>  
         {/* <div className={`col-md-4 ${isSidebarOpen ? '' : 'mx-auto'}`}> */}
         <div className='card-wrapper'>
          <div className='col-md-4'>
          <div className="card card2">
         <h3>Append <br/> My List</h3>
         <p>Choose Append Type</p>
         <FontAwesomeIcon icon={faCircleDown} className="down-arrow-icon" />
         </div>
          </div>
         </div>
         
         {/* <div className={`col-md-4 ${isSidebarOpen ? '' : 'mx-auto'}`}> */}
         <div className='card-wrapper'>
          <div className='col-md-4'>
          <div className='card card3'>
          <h3>Order <br/> History</h3>
                <p>0 Appends | 0 Lists</p>
                <FontAwesomeIcon icon={faCircleDown} className="down-arrow-icon" />
          </div>
          </div>
         </div>
         </div>

         {/* Additional Details*/}
            <div className='additional-details'>
             <div className='row'>
              <div className='col-md-4'>
              <div className='details1'>
                <FontAwesomeIcon icon={faBell} style={{fontSize:"20px",  marginRight: "5px", color:""}}/>
                 Count Details
                </div>
                <table className='custom--table1'>
                  <thead>
                    <tr>
                    <th>Module</th>
                    <th>Campaign Type</th>
                    <th>Quantity</th>
                    <th>Date</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className='col-md-4'>
              <div className='details2'>
                <FontAwesomeIcon icon={faBell} style={{fontSize:"20px",  marginRight: "5px", color:""}}/>
                Append Details
                </div>
                <table className='custom--table2'>
                  <thead>
                    <tr>
                    <th>FileType</th>
                    <th>Total Count</th>
                    <th>Match Count</th>
                    <th>Date</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className='col-md-4'>
                <div className='details3'>
                <FontAwesomeIcon icon={faBell} style={{fontSize:"20px",  marginRight: "5px", color:""}}/>
                Order History
                </div>
                <table className='custom--table3'>
                  <thead>
                    <tr>
                    <th>Order Description</th>
                    <th>Counts</th>
                    <th>Date</th>
                    </tr>
                  </thead>
                </table>
              </div>
             </div>
            </div>
        </div>
        </div>
         {/*User Home Page End*/}
      </div>
      
      
  );
}


export default Dashboard;