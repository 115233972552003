import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLeaf, faRightFromBracket,  faCaretDown, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

function Sidebar( {isOpen, toggleSidebar, currentPage, children}) {
    const [isDatazappSystemOpen, setDatazappSystemOpen] = useState(false);
    

    const toggleDatazappSystem = () => {
      setDatazappSystemOpen(!isDatazappSystemOpen);
    };



  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        {children}
      <ul className='sidebar-list'>
      <li className={`Home-page ${currentPage === 'home' ? 'active' : ''}`}>
          <FontAwesomeIcon icon={faHome} /> HomePage
          {currentPage === 'home' && 
          <FontAwesomeIcon icon={faCaretLeft} style={{ fontSize: '25px' }} className="left-arrow" />}
        </li>
        <li className='Datazapp-system' onClick={toggleDatazappSystem}>
          <FontAwesomeIcon icon={faLeaf} /> Datazapp System
        </li>
        <div className='sub-list'>
        <li>Appends</li>
        <li>Counts & List Orders</li>
        <li>Data Utilities</li>
        <li>Voter Vision</li>
        <li>My Account</li>
        <li>DataZapp Files</li>
        <li>App History</li>
        </div>
       
        <li className='Log-out'>
            <FontAwesomeIcon icon={faRightFromBracket}/> Logout
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;