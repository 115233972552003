import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faKey, faUser, faUserPlus, faBars } from '@fortawesome/free-solid-svg-icons';
import logo from "./Images/DatazappLogo.png";

function Navbar({ toggleSidebar } )
{
    return(
        <div className="navbar">
           <button className="toggle-button" onClick={toggleSidebar} style={{ outline: 'none' }}>
        <FontAwesomeIcon icon={faBars} />
      </button>
    <div className="navbar-left">
      <img src={logo} alt="Datazapp Logo" className="logo-img"/>
    </div>
    <div className="navbar-right">
      <span  className="blue-box">My Activity</span>
      <span  className="blue-box">My Suppression</span>
      <span  className="blue-box">Buy Credits</span>
      <span  className="blue-box">Orders</span>
      <span  className="blue-box">Pricing</span>
      <span  className="blue-box">Balance: $0</span>
      <div className="user-menu">
        <span>Harshal Joshi</span>
        <div className="dropdown-menu"> 
        <a href="#" className="custom-link">
        <div className="menu-column">
    <FontAwesomeIcon icon={faUser} /> My Profile
    </div>
        </a>
        <a href="#" className="custom-link">
        <div className="menu-column">
    <FontAwesomeIcon icon={faUserPlus} /> Invite
    </div>
        </a>
    <a href="#" className="custom-link">
    <div className="menu-column">
    <FontAwesomeIcon icon={faKey} /> Log out
    </div>
    </a>
       </div>
      </div>
    </div>
  </div>
    );
}

export default Navbar;