import React, {useState, useEffect} from "react";
import * as Components from './Components';
import "./styles.css";   
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock, faPhone, faBuilding } from '@fortawesome/free-solid-svg-icons';
import Dashboard from "./Dashboard";


function App() {
    const [signIn, toggle] = React.useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    

  // Function to handle login
  const handleLogin = () => {
    // Replace this logic with your actual login logic
    // For example, you might make an API call to authenticate the user
    // If login is successful, set isLoggedIn to true
    setIsLoggedIn(true);
    document.title = "Home Page | Datazapp Count System";
};

  const handleLogout = () => {
    // Perform any necessary logout actions
    // For example, clearing user data, tokens, etc.
    setIsLoggedIn(false);
  };


// Conditionally render the Dashboard component if isLoggedIn is true
if (isLoggedIn) {
    return <Dashboard />;
}

  return(
         <Components.Container>
             <Components.SignUpContainer signinIn={signIn}>
                 <Components.Form>
                     <Components.Title>Register</Components.Title>
                      <span className="input-with-icon">
                      <FontAwesomeIcon icon={faUser} className="icon" />
                      <Components.Input type="text" placeholder="Name" />
                      </span>
                    
                      <span className="input-with-icon">
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      <Components.Input type='email' placeholder='Email'/>
                      </span>
                    
                     <Components.PasswordInputs>
                     <span className="input-with-icon">
                      <FontAwesomeIcon icon={faLock} className="icon" />
                      <Components.Input type='password' placeholder='Password' />
                      </span>
                      <span className="input-with-icon">
                      <FontAwesomeIcon icon={faLock} className="icon" />
                      <Components.Input type='password' placeholder='Confirm Password' />
                      </span>
                     
                     </Components.PasswordInputs>
                     <Components.PasswordHint>
                            Minimum 8 characters, must contain at least one digit, lowercase, uppercase, and symbol (!, @, #, $, ^, &, *).
                        </Components.PasswordHint>

                      
                      <Components.PhoneTitle>Phone</Components.PhoneTitle>
                     <Components.PhoneContainer>
                     <Components.PhoneInput
                     type='tel'
                     placeholder='(xxx)'
                     />
                     <Components.PhoneInput
                     type='tel'
                     placeholder='xxx'
                     />
                     <Components.PhoneInput
                     type='tel'
                     placeholder='xxxx'
                     />
                     </Components.PhoneContainer>

                     <span className="input-with-icon">
                      <FontAwesomeIcon icon={faBuilding} className="icon" />
                      <Components.Input type='text' placeholder='Company' />
                      </span>

                     
                      
                    <Components.Description><strong>Tell us a little about yourself </strong> (optional, but help us serve you better)</Components.Description>

                    <Components.dTitle>What Industry do you work in?</Components.dTitle> {/* New title */}
                     <Components.Select> 
                     <option value=''>Please Select </option>
                     <option value='industry1'>Advertising/Marketing Agency</option>
                     <option value='industry2'>Automotive</option>
                     <option value='industry3'>Real Estate Wholesaler/Investor/REI</option>
                     <option value='industry4'>Political</option>
                     <option value='industry5'>Call Center/Market Research</option>
                     <option value='industry6'>List Brokers</option>
                     <option value='industry7'>Others</option>
                    </Components.Select>

                    <Components.TextAreaTitle>Describe data or services you are interested in and estimated volume</Components.TextAreaTitle>
                    <Components.TextArea rows='4'  placeholder='Example: Need Email Append/10,000 per month' />

                     <Components.Button  style={{ marginTop: '20px' }}> Register </Components.Button>
                 </Components.Form>
             </Components.SignUpContainer>

             <Components.SignInContainer signinIn={signIn}>
                  <Components.Form>
                      <Components.Title>Login</Components.Title>

                      <span className="input-with-icon">
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      <Components.Input type='email' placeholder='Email' />
                      </span>

                      <span className="input-with-icon">
                      <FontAwesomeIcon icon={faLock} className="icon" />
                      <Components.Input type='password' placeholder='Password'  />
                      </span>

                      <Components.LinksContainer>
                      <Components.Anchor href='#'>Forgot password </Components.Anchor>
                       <span>|</span> 
                      <Components.Anchor href='#'>Resend verification</Components.Anchor>
                      </Components.LinksContainer>
                      <Components.Button  onClick={handleLogin} style={{ marginTop: '20px' }} >Login</Components.Button>
                  </Components.Form>
             </Components.SignInContainer>

             <Components.OverlayContainer signinIn={signIn}>
                 <Components.Overlay signinIn={signIn}>

                 <Components.LeftOverlayPanel signinIn={signIn}>
                     <Components.title>Already part of the Datazapp family?</Components.title>
                     <Components.Paragraph>
                     Sign in and unlock the power of your data
                     </Components.Paragraph>
                     <Components.GhostButton onClick={() => toggle(true)}>
                         Sign In
                     </Components.GhostButton>
                     </Components.LeftOverlayPanel>

                     <Components.RightOverlayPanel signinIn={signIn}>
                       <Components.title>New to Datazapp ?</Components.title>
                       <Components.Paragraph>
                        Join us and experience the future of data
                       </Components.Paragraph>
                           <Components.GhostButton onClick={() => toggle(false)}>
                               Register
                           </Components.GhostButton> 
                     </Components.RightOverlayPanel>
 
                 </Components.Overlay>
             </Components.OverlayContainer>

         </Components.Container>
         
     );
}

export default App;