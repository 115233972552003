import styled from 'styled-components';
import React, { useState } from "react";

export const Container = styled.div`
background-color: #fff;
border-radius: 10px;
box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
position: relative;
overflow: hidden;
display: flex;
width: 100%;
max-width: 1100px;
min-height: 800px;
margin-top:50px;
margin-left:350px;
`;

export const SignUpContainer = styled.div`
 position: absolute;
 top: 0;
 height: 100%;
 transition: all 0.6s ease-in-out;
 left: 0;
 width: 50%;
 opacity: 0;
 z-index: 1;
 ${props => props.signinIn !== true ? `
   transform: translateX(100%);
   opacity: 1;
   z-index: 5;
 ` 
 : null}
`;


export const SignInContainer = styled.div`
position: absolute;
top: 0;
height: 100%;
transition: all 0.6s ease-in-out;
left: 0;
width: 50%;
z-index: 2;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
${props => (props.signinIn !== true ? `transform: translateX(100%);` : null)}
`;

export const LinksContainer = styled.div`
  display: flex;
  gap: 20px; /* Add some gap between the links */
  margin-top: 20px;
  align-items: center; /* Add top margin for separation from the rest of the form */
`;


export const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%; /* Make sure the phone container spans the full width */
  margin: 10px 0;
  justify-content: space-between;
`;


export const PhoneTitle = styled.p`
color: #00000;
  font-weight: bold;
  font-size: 14px;
  margin: 5px 10px;
   text-align: left;
`;

export const PhoneInput = styled.input`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  width: 33.33%;  
  
`;


export const Form = styled.form`
background-color: #ffffff;
display: flex;
align-items: stretch;
justify-content: center;
flex-direction: column;
padding: 0 50px;
height: 100%;
text-align: center;
padding-top: 20px; /* Add top padding to center content vertically */
box-sizing: border-box;
`;



// Add a new styled component for a div to contain the password inputs side by side
export const PasswordInputs = styled.div`
  display: flex;
  gap: 20px; /* Add some gap between the password inputs */
  width: 100%;
`;
export const PasswordHint = styled.p`
    color: #00000;
    font-size: 14px;
    margin: 10px 10px;
    text-align: left;
`;

export const Select = styled.select`
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
`;

export const Description = styled.p`
  color: #00000;
  font-weight: normal;
  font-size: 14px;
  margin: 10px 10px;
  text-align: left;

   strong { /* Style for the text within <strong> tags */
    font-weight: bold; /* Remove bold style from text within <strong> tags */
  }
`;

export const TextAreaTitle = styled.p`
  color: #00000;
  font-weight: regular;
  font-size: 14px;
  margin: 10px 10px;
   text-align: left;
`;


export const TextArea = styled.textarea`
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  width: 100%;
  resize: vertical;
`;

export const dTitle = styled.p`
color: #00000;
font-weight: regular;
font-size: 14px;
text-align: left;
margin: 10px 10px;
`;

export const title = styled.h1`
font-family: 'Abril Fatface', cursive;
color: #eee;
font-weight: regular;
margin: 0;
`;


export const Title = styled.h1`
font-family: 'Abril Fatface', cursive;
color: #064273;
font-weight: regualr;
margin: 0;
`;

export const Input = styled.input`
background-color: #eee;
border: none;
padding: 12px 15px 12px 35px;
margin: 8px 0;
width: 100%;
position: relative;

&::before {
  content: ''; /* Add an empty content */
  position: absolute;
  left: 10px; /* Adjust the left position to your liking */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #333; /* Icon color */
}
`;


export const Button = styled.button`
   border-radius: 20px;
   border: 1px solid #1ABC9C;
   background-color: #1ABC9C;
   color: #ffffff;
   font-size: 12px;
   font-weight: bold;
   padding: 12px 45px;
   letter-spacing: 1px;
   text-transform: uppercase;
   margin-bottom:15px;
   transition: transform 80ms ease-in;
   &:active{
       transform: scale(0.95);
   }
   &:focus {
       outline: none;
   }
`;
export const GhostButton = styled(Button)`
background-color: transparent;
border-color: #ffffff;
`;

export const Anchor = styled.a`
color: #333;
font-size: 15px;
text-decoration: none;
margin: 20px 5px;
`;
export const OverlayContainer = styled.div`
position: absolute;
top: 0;
left: 50%;
width: 50%;
height: 100%;
overflow: hidden;
transition: transform 0.6s ease-in-out;
z-index: 100;
${props =>
 props.signinIn !== true ? `transform: translateX(-100%);` : null}
`;

export const Overlay = styled.div`
background: #ff416c;
background: -webkit-linear-gradient(to left, #1abc9c, #337ab7);
background:  linear-gradient(#1abc9c, #337ab7);
background-repeat: no-repeat;
background-size: cover;
background-position: 0 0;
color: #ffffff;
position: relative;
left: -100%;
height: 100%;
width: 200%;
transform: translateX(0);
transition: transform 0.6s ease-in-out;
${props => (props.signinIn !== true ? `transform: translateX(50%);` : null)}
`;

export const OverlayPanel = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
`;

export const LeftOverlayPanel = styled(OverlayPanel)`
  transform: translateX(-20%);
  ${props => props.signinIn !== true ? `transform: translateX(0);` : null}
`;

export const RightOverlayPanel = styled(OverlayPanel)`
    right: 0;
    transform: translateX(0);
    ${props => props.signinIn !== true ? `transform: translateX(20%);` : null}
`;

export const Paragraph = styled.p`
font-family: 'Lato', sans-serif;
font-size: 18px;
  font-weight: 100;
  color: #0C090A;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px
`;

